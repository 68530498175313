import React from "react";
import "./index.scss";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import { useEffect, useState } from "react";
import experienceData from "../../data/experience.json";

const Experience = () => {
  const [letterClass, setLetterClass] = useState("text-animate");

  useEffect(() => {
    setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);
  }, []);

  const renderExperience = (experience) => {
    return (
      <div className="cards-container">
        {experience.map((exp, idx) => {
          return (
            <a
              href={exp.link}
              target="_blank"
              rel="noopener noreferrer"
              key={idx}
              className="card"
            >
              <div className="card-image">
                <img
                  src={exp.image}
                  alt={`${exp.company} logo`}
                  className="experience-image"
                />
              </div>
              <div className="card-content">
                <div className="text-header">
                    <h2 className="company">{exp.company}</h2>
                    <div className="date-location">{exp.date} | {exp.location}</div>
                </div>
                <h3 className="role">{exp.role}</h3>
                <p className="summary">{exp.summary}</p>
                </div>

            </a>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="container portfolio-page">
        <h1 className="page-title">
          <AnimatedLetters
            letterClass={letterClass}
            strArray={['E','x','p','e','r', 'i', 'e', 'n','c','e']}
            idx={10}
          />
        </h1>
        <div>{renderExperience(experienceData.experience)}</div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Experience;
